import React from 'react'
import { AiFillLinkedin,AiFillFacebook,AiFillInstagram } from 'react-icons/ai';

function footer() {
  return (
    <div className='grid grid-cols-2 bg-slate-700'>
        <div className='font-7xl ml-5 text-white'>Western Metal Industries © 2022. All Rights Reserved.</div>
        <div className='flex justify-end'>
            <AiFillLinkedin size={30} className="mx-1 text-white rounded"/>
            <AiFillFacebook size={30} className="mx-1 text-white rounded"/>
            <AiFillInstagram size={30} className="mx-1 text-white rounded"/>
        </div>
    </div>

  )
}

export default footer