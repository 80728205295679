import React from 'react'
import { Menu, Transition } from '@headlessui/react'
import { Fragment} from 'react'

// function EditInactiveIcon(props) {
//     return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4 13V16H7L16 7L13 4L4 13Z"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //     </svg>
  //   )
  // }
  
  // function EditActiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4 13V16H7L16 7L13 4L4 13Z"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //     </svg>
  //   )
  // }
  
  // function DuplicateInactiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4 4H12V12H4V4Z"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //       <path
  //         d="M8 8H16V16H8V8Z"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //     </svg>
  //   )
  // }
  
  // function DuplicateActiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path
  //         d="M4 4H12V12H4V4Z"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //       <path
  //         d="M8 8H16V16H8V8Z"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //     </svg>
  //   )
  // }
  
  // function ArchiveInactiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <rect
  //         x="5"
  //         y="8"
  //         width="10"
  //         height="8"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //       <rect
  //         x="4"
  //         y="4"
  //         width="12"
  //         height="4"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //       <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
  //     </svg>
  //   )
  // }
  
  // function ArchiveActiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <rect
  //         x="5"
  //         y="8"
  //         width="10"
  //         height="8"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //       <rect
  //         x="4"
  //         y="4"
  //         width="12"
  //         height="4"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //       <path d="M8 12H12" stroke="#A78BFA" strokeWidth="2" />
  //     </svg>
  //   )
  // }
  
  // function MoveInactiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M10 4H16V10" stroke="#A78BFA" strokeWidth="2" />
  //       <path d="M16 4L8 12" stroke="#A78BFA" strokeWidth="2" />
  //       <path d="M8 6H4V16H14V12" stroke="#A78BFA" strokeWidth="2" />
  //     </svg>
  //   )
  // }
  
  // function MoveActiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <path d="M10 4H16V10" stroke="#C4B5FD" strokeWidth="2" />
  //       <path d="M16 4L8 12" stroke="#C4B5FD" strokeWidth="2" />
  //       <path d="M8 6H4V16H14V12" stroke="#C4B5FD" strokeWidth="2" />
  //     </svg>
  //   )
  // }
  
  // function DeleteInactiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <rect
  //         x="5"
  //         y="6"
  //         width="10"
  //         height="10"
  //         fill="#EDE9FE"
  //         stroke="#A78BFA"
  //         strokeWidth="2"
  //       />
  //       <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
  //       <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
  //     </svg>
  //   )
  // }
  
  // function DeleteActiveIcon(props) {
  //   return (
  //     <svg
  //       {...props}
  //       viewBox="0 0 20 20"
  //       fill="none"
  //       xmlns="http://www.w3.org/2000/svg"
  //     >
  //       <rect
  //         x="5"
  //         y="6"
  //         width="10"
  //         height="10"
  //         fill="#8B5CF6"
  //         stroke="#C4B5FD"
  //         strokeWidth="2"
  //       />
  //       <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
  //       <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
  //     </svg>
  //   )
  // )}


function navbar() {
  return (

    <div>
        <nav className='bg-gray-700 h-15v'>
            <div className="max-w-7xl mx-auto px-8">
                <div className="flex items-center justify-between">
                    <div className="w-full justify-between flex items-center">
                        <a className="flex-shrink py-5" href="/">
                            <img width={180} height={100} src={require("../static/western.jpg")}  alt="Workflow"/>
                        </a>
                        <div className="hidden md:block">
                            <div className="ml-10 flex items-baseline space-x-4">
                                <a className="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" href="/#description">
                                    About Us
                                </a>

                                
                                {/* <Menu as="div" className="relative inline-block text-left">
                                <div>
                                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    Options
                                    <ChevronDownIcon
                                    className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                    aria-hidden="true"
                                    />
                                </Menu.Button>
                                </div>
                                <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                >
                                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <EditActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <EditInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Edit
                                        </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <DuplicateActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <DuplicateInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Duplicate
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                    <div className="px-1 py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <archiveActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <archiveInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Archive
                                        </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <MoveActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <MoveInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Move
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                    <div className="px-1 py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <DeleteActiveIcon
                                                className="w-5 h-5 mr-2 text-violet-400"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <DeleteInactiveIcon
                                                className="w-5 h-5 mr-2 text-violet-400"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Delete
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                </Menu.Items>
                                </Transition>
                            </Menu>
                                
                            <Menu as="div" className="relative inline-block text-left">
                                <div>
                                <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-white bg-black rounded-md bg-opacity-20 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                                    Products
                                    <ChevronDownIcon
                                    className="w-5 h-5 ml-2 -mr-1 text-violet-200 hover:text-violet-100"
                                    aria-hidden="true"
                                    />
                                </Menu.Button>
                                </div>
                                <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                                >
                                <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="px-1 py-1 ">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <EditActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <EditInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Edit
                                        </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <DuplicateActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <DuplicateInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Duplicate
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                    <div className="px-1 py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <archiveActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <archiveInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Archive
                                        </button>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <MoveActiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <MoveInactiveIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Move
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                    <div className="px-1 py-1">
                                    <Menu.Item>
                                        {({ active }) => (
                                        <button
                                            className={`${
                                            active ? 'bg-violet-500 text-white' : 'text-gray-900'
                                            } group flex rounded-md items-center w-full px-2 py-2 text-sm`}
                                        >
                                            {active ? (
                                            <DeleteActiveIcon
                                                className="w-5 h-5 mr-2 text-violet-400"
                                                aria-hidden="true"
                                            />
                                            ) : (
                                            <DeleteInactiveIcon
                                                className="w-5 h-5 mr-2 text-violet-400"
                                                aria-hidden="true"
                                            />
                                            )}
                                            Delete
                                        </button>
                                        )}
                                    </Menu.Item>
                                    </div>
                                </Menu.Items>
                                </Transition>
                            </Menu> */}
                                <a className="text-gray-300  hover:text-gray-800 dark:hover:text-white px-3 py-2 rounded-md text-sm font-medium" href="/#">
                                    Contact
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <div className="ml-4 flex items-center md:ml-6">
                        </div>
                    </div>
                    <div className="-mr-2 flex md:hidden">
                        <button className="text-gray-800 dark:text-white hover:text-gray-300 inline-flex items-center justify-center p-2 rounded-md focus:outline-none">
                            <svg width="20" height="20" fill="currentColor" className="h-8 w-8" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1664 1344v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45zm0-512v128q0 26-19 45t-45 19h-1408q-26 0-45-19t-19-45v-128q0-26 19-45t45-19h1408q26 0 45 19t19 45z">
                                </path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div className="hidden" >
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <a className="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                        Home
                    </a>
                    <a className="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                        Gallery
                    </a>
                    <a className="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                        Content
                    </a>
                    <a className="text-gray-300 hover:text-gray-800 dark:hover:text-white block px-3 py-2 rounded-md text-base font-medium" href="/#">
                        Contact
                    </a>
                </div>
            </div>
        </nav>
    </div>

  )
}

export default navbar;