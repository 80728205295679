import React from 'react'

export default function process() {
  return (
    <div className="flex-col pt-10 pl-2  w-screen max-h-fit bg-slate-500 items-center justify-center">
        <div className="text-7xl text-center">Our Process</div>
        <br />
        <div className="text-2xl text-justify px-10 my-10"> 
            {"We use latest technique of melting Pure Aluminium with integrated casting and online hot and cold rolling. Basic material is high quality virgin aluminium of consistent purity. The structure of our rolled strips ensure the best mettalurgical characteristics for cold impact extrusion and deep drawing process. As a producer with rich trade experience we invest money and time to build reliable and long term business relationships. We continuously develop our quality, service and product range and consider this to be a key moment of our company's global strategy."}
        </div>
        
        <div id="slider" className='flex  overflow-x-scroll overflow-y-hidden'>
            
            <div className='flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Melting</div>
                    <div className="text-gray-700 text-justify">
                        {"The metal base is melted in the melting furnace."}
                        {"Bottom pouring furnace eliminates SLAG, increases production, maintains consistency in QUALITY."}
                        {"Current annual capacity of over 15000 Tons."}
                    </div>
                    
                </div>
            </div>

            <div className='flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Caster</div>
                    <div className="text-gray-700 text-base">
                        The molten metal is fed into a strip caster.
                        A continuous stream of liquid aluminium is fed into the wheel mould which solidify with seconds to an endless aluminium strip.
                    </div>
                    
                </div>
            </div>

            <div className='flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Rolling</div>
                    <div className="text-gray-700 text-base">
                        The strip, that has been rolled down to end thickness, passes through a hot and cold rolling stage thus giving it an optimum metal structure.
                        Online Thickness Gauge is used to control the thickness of rolled strips.                    
                    </div>
                </div>
            </div>

            <div className='sm:col-span-3 lg:col-span-1 flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Coiler</div>
                    <div className="text-gray-700 text-base">
                        Coiler is used for Maximum Recovery, Minimum deformation and least changeover time.
                    </div>
                    
                </div>
            </div>

            <div className='sm:col-span-3 lg:col-span-1 flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")}alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Punching</div>
                    <div className="text-gray-700 text-base">
                        The coils feeds through a continuous stretcher to an automatic blanking press producing slugs of varied size.
                        16 HIGH SPEED presses help to cater, different Clients & their various sizes.
                        Existing capacity of 9000 Tons annually.
                    </div>
                    
                </div>
            </div>

            <div className='sm:col-span-3 lg:col-span-1 flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")}alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Annealing</div>
                    <div className="text-gray-700 text-base">
                            The newly produced slugs passes through Annealing process in an Annealing furnace.
                            While installed furnaces take care of current production, standby facility is capable to match Increased Quantity.
                    </div>
                </div>
            </div>

            <div className='sm:col-span-3 lg:col-span-1 flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Surface Finishing</div>
                    <div className="text-gray-700 text-base">
                        As per clients requirements
                    </div>
                </div>
            </div>

            <div className='sm:col-span-3 lg:col-span-1 flex-shrink-0 w-96 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
                <img width={300} height={200} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
                <div className="px-6 py-4 mb-10">
                    <div className="font-bold text-xl mb-2">Packaging</div>
                    <div className="text-gray-700 text-base"></div>
                </div>
            </div>
        </div>
    </div>
        

  )
}

