import React,{ useState,useEffect } from 'react'

function infrasturcture() {

  

  return (
    <div className='grid grid-cols-1 lg:grid-cols-2 text-center bg-slate-500'>
      <div id="map" className='flex-col items-center justify-center'>
        <div className="text-7xl text-center">Our Main Office</div>
        <div className='flex grid-cols-3 items-center justify-center shadow m-5'>
            
            <div className="flex-col items-center justify-center my-10 mx-10"> 
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7566.83409426378!2d73.91036862655908!3d18.51004667349175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1eae5e19f27%3A0xc0ce9649a420bf03!2sHadapsar%20Industrial%20Estate%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1648876818257!5m2!1sen!2sin" width="200" height="200" allowFullScreen loading="lazy" className='block lg:hidden'></iframe>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7566.83409426378!2d73.91036862655908!3d18.51004667349175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c1eae5e19f27%3A0xc0ce9649a420bf03!2sHadapsar%20Industrial%20Estate%2C%20Hadapsar%2C%20Pune%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1648876818257!5m2!1sen!2sin" width="500" height="500" allowFullScreen loading="lazy" className='hidden lg:block p-10'></iframe>
            </div>

            <div className='mr-5 colspan-1 text-white font-bold text-xs md:text-sm'>
              251/1 A,<br/>
              B Hadapsar Industrial Estate,<br/>
              Hadapsar,<br/>
              Pune - 411013<br/>
              Maharashtra<br/>
              India<br/>
            </div>
            
        <div>

          </div>  
        </div>
      </div>
      <div id="graph">
        <div className="text-7xl text-center">Aluminium News</div>
        <div></div>
      </div>
    </div>
  )
}

export default infrasturcture