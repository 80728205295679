import React from 'react'

function Products() {
  return (
    <div className="sm:flex-col sm:max-h-full lg:grid grid-cols-3 w-screen max-h-fit">
        <div className='col-span-3 mt-5 text-center text-7xl'>Products</div>
        <div className='sm:col-span-3 lg:col-span-1 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
            <img width={400} height={250} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
            <div className="px-6 py-4">
                <div className="font-bold text-xl">Aluminium Slug</div>
                <p className="text-gray-700 text-base">
                    Standards : EN 570, EN 573-3 & ISO 6506-4
                    <br/>
                    Type : Pierced Slugs, Rigid Slugs
                    <br/>
                    Shapes : Flat Domed, Conical Domed Spherical
                    </p>
                <button className='mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'>Read More</button>
            </div>
        </div>

        <div className='sm:col-span-3 lg:col-span-1 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
            <img width={400} height={250} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
            <div className="px-6 py-4">
                <div className="font-bold text-xl">Aluminium Strips, Sheets & Coils</div>
                <p className="text-gray-700 text-base">
                Standards : EN 570, EN 573-3 & ISO 6506-4
                <br/>
                Used for:
                <ul>
                    <li>Roll bond panels (Deep Freezer compartment Refigerator)</li>
                    <li>Heat Sink (Heat Exchanger)</li>
                    <li>Automobile Parts</li>
                    <li>Fan Blade Stock</li>
                </ul>
                </p>
                <button className='mt-10 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'>Read More</button>
            </div>
        </div>
        
        <div className='sm:col-span-3 lg:col-span-1 text-center mt-10 mb-16 border bg-slate-400 pt-20 mx-10 border-gray-500 shadow'>
            <img width={400} height={250} className="w-full px-20" src={require("../static/slugs.jpg")} alt="Aluminium Slugs" />
            <div className="px-6 py-4">
                <div className="font-bold text-xl mb-2">Aluminium Circles</div>
                <p className="text-gray-700 text-base">
                    Surface: Plain
                    <br/>
                    Used for:
                    <ul>
                        <li>Cookware Products</li>
                        <li>Utensils Products</li>
                    </ul>
                </p>
                <button className='mt-10 mb-20 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'>Read More</button>
            </div>
        </div>
        
        
        
    </div>

  )
}

export default Products