import React from 'react'

function description() {
  return ( 
    <div className="flex w-full md:max-h-screen ">
        <div className='max-h-screen overflow-scroll pl-10 my-10 scroll flex-0.5 '>
        <div className='overflow-scroll hidden md:block '>
            <img width={800} height={1000}src={require("../static/welcome.jpg")} alt="" className='py-5'/>
            <img width={800} height={1000}src={require("../static/welcome.jpg")} alt="" className='py-5'/>
            <img width={800} height={1000}src={require("../static/welcome.jpg")} alt="" className='py-5'/>
            <img width={800} height={1000}src={require("../static/welcome.jpg")} alt="" className='py-5'/>
        </div>
        </div>
        
       
        <div className="flex-col items-center justify-center text-center my-10 flex-0.5 m-5 bg-gray-300">
            <heading className="md:text-7xl text-5xl mx-5 font-bold text-center">Welcome to Western Aluminium</heading>
            <div className='md:mt-10 md:mx-16 mt-5 mx-5 md:text-left text-center md:text-lg sm:text-xs mb-10'>
                {"Western Group of Companies was established in 1960 by a group of promoters having experience of over 30 years in the field of Aluminium and Metal manufacturing. Today, Western Metal Industries Pvt. Ltd. is one of the largest manufacturers of Aluminium Circles, Aluminium Sheets, Aluminium Strips and Aluminium Slugs in India."}
                <br/>
                {"Our products are widely used in the industry for manufacturing of" }<br/>
                {"♦ Aerosol cans"}<br/>
                {"♦ Collapsible tubes"}<br/>
                {"♦ Utensils"}<br/>
                {"♦ Pesticide bottles"}<br/>
                {"♦ Containers"}<br/>
                {"♦ Cookware"}
                <br/>
                {"Our modern Manufacturing plants are located at Hadapsar, Pune and Bhandgaon about 50 kms from Pune. We use the latest technique of melting Aluminium with integrated casting and online hot and cold rolling to produce fine quality of final product. We ONLY use 99.7% Pure Aluminium (ENAW-1070A for Tubes and ENAW-1050A for cans)"}
                {"We use advanced technology, high precision equipments, efficient quality control and decades of experience with us, to produce best quality product."}
                {"As a producer with rich trade experience we invest money and time to build reliable and long term business relationships. We continuously develop our quality, service and products range and consider this to be a key moment of our company's global strategy."}
                {"Our products meet the highest quality standard and are well accepted in domestic as well as International market. We export to various countries worldwide and are one of the leading Aluminium Slug Exporters in India."}
            </div>
        </div>
    </div>
  )
}

export default description
