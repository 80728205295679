import Navigation from './components/navbar'
import Slider from './components/slider'
import Description from './components/description'
import Contact from './components/contact'
import Process from './components/process'
import Products from './components/Products'
import Infrasturcture from './components/infrasturcture'
import Footer from './components/footer'
import React from 'react'


function App() {
  return (
    <div className="flex-col w-full h-full snap-y snap-mandatory overflow-hidden">
    <div className=" bg-grey-800 w-full fixed z-10"> 
      <Navigation className="snap-start" />
    </div>
    
      
      
      
      <Slider className=" snap-start flex w-screen h-screen items-center justify-center"/>
      <Description className="snap-start flex w-screen h-screen items-center justify-center"/>
      <Process className="snap-start flex w-screen h-screen items-center justify-center"/>
      <Products className="snap-start flex w-screen h-screen items-center justify-center"/>
      <Infrasturcture className="snap-start flex w-screen h-screen items-center justify-center"/>
      <Footer className="snap-start flex w-screen h-screen items-center justify-center"/>


      
      

    </div>
    
  )
}

export default App;
