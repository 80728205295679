import React,{useState} from 'react'
import { MdOutlineArrowBackIosNew, MdOutlineArrowForwardIos } from 'react-icons/md';


// const SliderImages =[
//   "./static/metal1.jpg",
//   "./static/metal2.jpg",
//   "./static/metal3.jpg",
// ];

function Slider() {
  const [currslide, setcurrslide]= useState(0)

  const increase = (e) =>
  {
    if(currslide===2) {setcurrslide(currslide===0); return;}
    setcurrslide(currslide+1);
  }

  const decrease = (e) =>
  {
    if(currslide===0) {setcurrslide(currslide===2);return;}
    setcurrslide(currslide-1);
  }

  return (
    <div id="Slider" className="flex w-full max-h-screen">
      
      <img width={2000} height={1000} src={require("../static/metal"+(currslide+1)+".jpg")} alt=""/>
      {/* <div className="absolute left-5 bottom-5 text-xl px:5 py:10 top-1/2 bg-red-300">
        Western Aluminium Hits a 1000 Crore
      </div> */}
      <div className='w-full absolute sm:top-1/4 lg:top-1/2 transform-y-1/2 px-2 flex justify-between items-center'>
        <button id="left" onClick={(e)=>decrease()}>
          <MdOutlineArrowBackIosNew size="50px" color='white' />
        </button>
        <button id="right" onClick={(e)=>increase()}>
          <MdOutlineArrowForwardIos size="50px" color='white'/>
        </button>
      </div>

    </div>
  )
}

export default Slider